@import url("https://fonts.googleapis.com/css?family=Press Start 2P");

:root {
  --game-height: calc((100vh - 80px));
  --game-width: calc(var(--game-height) * (17 / 20));
  --game-max-width: calc(100vw - 32px);
  --game-max-height: calc(100vh - 60px);
}

html,
body {
  font-family: "Press Start 2P", sans-serif;
  background-color: #eb7dff;
  height: 100%;
}

.bg-dark {
  background-color: #a5dbfe !important;
}

.nav-link {
  color: #512a8e;
}

.navbar-text {
  color: #512a8e;
}

.navbar-dark {
  --bs-navbar-color: #776594;
  --bs-navbar-hover-color: #512a8e;
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #735d97;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
}

.card-body {
  background-color: #ffe400;
  color: #512a8e;
}

.navbar {
  border-bottom: 4px solid #512a8e;
}

.card {
  --bs-card-border-width: 4px;
  --bs-card-border-color: #512a8e;
  --bs-card-inner-border-radius: 0px;
}

.game-card:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.8);
}

.main-div {
  margin: auto;
  top: 68px;
  position: relative;
  overflow: hidden;
}

.game-div {
  margin: auto;
  overflow: hidden;
}
.game-div-wrapper {
  position: fixed;
  width: 100%;
  top: 68px;
  overflow: hidden;
}

@keyframes float {
  0% {
    text-shadow: 2px 5px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    text-shadow: 5px 10px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-5px);
  }
  100% {
    text-shadow: 2px 5px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.keyboard-controls {
  text-align: center;
  margin: auto;
  color: #fff;
  transform: translatey(0px);
  animation: float 2s ease-in-out infinite;
}
